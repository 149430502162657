module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"האקדמיה לעיצוב עוגות","short_name":"האקדמיה","description":"מגוון קורסים דיגיטליים שיעזרו לך לממש כל רעיון שיש לך בדמיון ולהפוך אותו לעוגה מעוצבת שמשאירה חותם ועושה וואו","lang":"he","start_url":"/dashboard/","background_color":"#f8e4df","theme_color":"#f8e4df","theme_color_in_head":false,"display":"standalone","icon":"src/img/academy-icon.png","icons":[{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"any"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"},{"src":"icons/icon-1024x1024.png","sizes":"1024x1024","type":"image/png","purpose":"any"}],"include_favicon":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"ac81e477b5271bae5de0cc34932633b3"},
    },{
      plugin: require('../plugins/gatsby-plugin-critical-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-3/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{"shouldFocusAfterRender":false,"shouldCloseOnOverlayClick":false,"shouldCloseOnEsc":false,"shouldReturnFocusAfterClose":false,"style":{"overlay":{"all":"unset"},"content":{"all":"unset"}}}},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://1ae719569903430580723ec9963b0faa@o371234.ingest.sentry.io/5183315","tracesSampleRate":1,"environment":"staging","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
