exports.components = {
  "component---src-components-course-template-jsx": () => import("./../../../src/components/CourseTemplate.jsx" /* webpackChunkName: "component---src-components-course-template-jsx" */),
  "component---src-components-episode-template-jsx": () => import("./../../../src/components/EpisodeTemplate.jsx" /* webpackChunkName: "component---src-components-episode-template-jsx" */),
  "component---src-components-inspirational-challenge-redirect-template-jsx": () => import("./../../../src/components/InspirationalChallengeRedirectTemplate.jsx" /* webpackChunkName: "component---src-components-inspirational-challenge-redirect-template-jsx" */),
  "component---src-components-inspirational-challenge-template-jsx": () => import("./../../../src/components/InspirationalChallengeTemplate.jsx" /* webpackChunkName: "component---src-components-inspirational-challenge-template-jsx" */),
  "component---src-components-inspirational-challenge-thanks-template-jsx": () => import("./../../../src/components/InspirationalChallengeThanksTemplate.jsx" /* webpackChunkName: "component---src-components-inspirational-challenge-thanks-template-jsx" */),
  "component---src-components-podcast-template-jsx": () => import("./../../../src/components/PodcastTemplate.jsx" /* webpackChunkName: "component---src-components-podcast-template-jsx" */),
  "component---src-components-sculpting-challenge-redirect-template-jsx": () => import("./../../../src/components/SculptingChallengeRedirectTemplate.jsx" /* webpackChunkName: "component---src-components-sculpting-challenge-redirect-template-jsx" */),
  "component---src-components-sculpting-challenge-template-jsx": () => import("./../../../src/components/SculptingChallengeTemplate.jsx" /* webpackChunkName: "component---src-components-sculpting-challenge-template-jsx" */),
  "component---src-components-sculpting-challenge-thanks-template-jsx": () => import("./../../../src/components/SculptingChallengeThanksTemplate.jsx" /* webpackChunkName: "component---src-components-sculpting-challenge-thanks-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-academy-abundance-jsx": () => import("./../../../src/pages/academy/abundance.jsx" /* webpackChunkName: "component---src-pages-academy-abundance-jsx" */),
  "component---src-pages-academy-competition-jsx": () => import("./../../../src/pages/academy/competition.jsx" /* webpackChunkName: "component---src-pages-academy-competition-jsx" */),
  "component---src-pages-academy-courses-jsx": () => import("./../../../src/pages/academy/courses.jsx" /* webpackChunkName: "component---src-pages-academy-courses-jsx" */),
  "component---src-pages-academy-exhibition-jsx": () => import("./../../../src/pages/academy/exhibition.jsx" /* webpackChunkName: "component---src-pages-academy-exhibition-jsx" */),
  "component---src-pages-academy-exhibition-redirect-jsx": () => import("./../../../src/pages/academy/exhibition/redirect.jsx" /* webpackChunkName: "component---src-pages-academy-exhibition-redirect-jsx" */),
  "component---src-pages-academy-exhibition-thanks-jsx": () => import("./../../../src/pages/academy/exhibition/thanks.jsx" /* webpackChunkName: "component---src-pages-academy-exhibition-thanks-jsx" */),
  "component---src-pages-academy-figures-sculpture-jsx": () => import("./../../../src/pages/academy/figures-sculpture.jsx" /* webpackChunkName: "component---src-pages-academy-figures-sculpture-jsx" */),
  "component---src-pages-academy-how-to-price-cakes-jsx": () => import("./../../../src/pages/academy/how-to-price-cakes.jsx" /* webpackChunkName: "component---src-pages-academy-how-to-price-cakes-jsx" */),
  "component---src-pages-academy-jsx": () => import("./../../../src/pages/academy.jsx" /* webpackChunkName: "component---src-pages-academy-jsx" */),
  "component---src-pages-academy-live-show-figure-jsx": () => import("./../../../src/pages/academy/live-show-figure.jsx" /* webpackChunkName: "component---src-pages-academy-live-show-figure-jsx" */),
  "component---src-pages-academy-live-show-figure-redirect-jsx": () => import("./../../../src/pages/academy/live-show-figure/redirect.jsx" /* webpackChunkName: "component---src-pages-academy-live-show-figure-redirect-jsx" */),
  "component---src-pages-academy-live-show-figure-thanks-jsx": () => import("./../../../src/pages/academy/live-show-figure/thanks.jsx" /* webpackChunkName: "component---src-pages-academy-live-show-figure-thanks-jsx" */),
  "component---src-pages-academy-live-show-jsx": () => import("./../../../src/pages/academy/live-show.jsx" /* webpackChunkName: "component---src-pages-academy-live-show-jsx" */),
  "component---src-pages-academy-security-forces-benefits-jsx": () => import("./../../../src/pages/academy/security-forces-benefits.jsx" /* webpackChunkName: "component---src-pages-academy-security-forces-benefits-jsx" */),
  "component---src-pages-academy-webinar-jsx": () => import("./../../../src/pages/academy/webinar.jsx" /* webpackChunkName: "component---src-pages-academy-webinar-jsx" */),
  "component---src-pages-academy-webinar-redirect-jsx": () => import("./../../../src/pages/academy/webinar/redirect.jsx" /* webpackChunkName: "component---src-pages-academy-webinar-redirect-jsx" */),
  "component---src-pages-academy-webinar-thanks-jsx": () => import("./../../../src/pages/academy/webinar/thanks.jsx" /* webpackChunkName: "component---src-pages-academy-webinar-thanks-jsx" */),
  "component---src-pages-cakes-jsx": () => import("./../../../src/pages/cakes.jsx" /* webpackChunkName: "component---src-pages-cakes-jsx" */),
  "component---src-pages-cakes-thanks-jsx": () => import("./../../../src/pages/cakes/thanks.jsx" /* webpackChunkName: "component---src-pages-cakes-thanks-jsx" */),
  "component---src-pages-dashboard-path-jsx": () => import("./../../../src/pages/dashboard/[...path].jsx" /* webpackChunkName: "component---src-pages-dashboard-path-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-workshops-jsx": () => import("./../../../src/pages/workshops.jsx" /* webpackChunkName: "component---src-pages-workshops-jsx" */),
  "component---src-pages-workshops-thanks-jsx": () => import("./../../../src/pages/workshops/thanks.jsx" /* webpackChunkName: "component---src-pages-workshops-thanks-jsx" */)
}

